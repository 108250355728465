import React from "react";
import theme from "theme";
import { Theme, Text, Box, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"politika-konfidencijnosti"} />
		<Helmet>
			<title>
				EduQuest
			</title>
			<meta name={"description"} content={"Ваш будиночок в горах із чанами для справжнього релаксу"} />
			<meta property={"og:title"} content={"EduQuest"} />
			<meta property={"og:description"} content={"Ваш будиночок в горах із чанами для справжнього релаксу"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} />
			<meta name={"msapplication-TileColor"} content={"#F2EFEA"} />
		</Helmet>
		<Section padding="16px 40px 16px 40px" quarkly-title="Header" position="absolute" sm-padding="16px 20px 16px 20px">
			<Override
				slot="SectionContent"
				flex-direction="row"
				align-items="center"
				display="flex"
				justify-content="space-between"
				width="100%"
				max-width="none"
			/>
			<Box
				empty-border-color="LightGray"
				width="30%"
				align-items="center"
				lg-width="50%"
				empty-min-height="64px"
				empty-border-style="solid"
				display="flex"
				sm-width="70%"
				empty-min-width="64px"
				empty-border-width="1px"
			>
				<Text margin="0px 0px 0px 0px" font="--headline2Lg">
					EduQuest
				</Text>
			</Box>
			<Box
				sm-width="30%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-style="solid"
				display="flex"
				align-items="center"
				empty-border-width="1px"
				empty-border-color="LightGray"
				width="70%"
				lg-width="50%"
			>
				<Components.QuarklycommunityKitMobileSidePanel>
					<Override slot="Content" justify-content="flex-end" padding="0px 0px 0px 0px" background="none" />
					<Override
						slot="Button Text"
						md-text-transform="uppercase"
						md-color="--darkL2"
						md-letter-spacing="1px"
						md-font="--base"
					/>
					<Override slot="Button Icon" md-color="--darkL2" />
					<Box
						md-padding="20px 0px 20px 0px"
						display="flex"
						justify-content="flex-end"
						align-items="center"
						md-flex-direction="column"
					>
						<Link text-decoration-line="initial" color="--darkL2" font="--base" href="/index">
							Головна
						</Link>
						<Link
							md-margin="20px 0px 0px 0px"
							href="/pro-nas"
							text-decoration-line="initial"
							color="--darkL2"
							font="--base"
							margin="0px 0px 0px 20px"
						>
							Про нас
						</Link>
						<Link
							color="--darkL2"
							font="--base"
							margin="0px 0px 0px 20px"
							md-margin="20px 0px 0px 0px"
							href="/poslugi"
							text-decoration-line="initial"
						>
							Послуги
						</Link>
						<Link
							color="--darkL2"
							font="--base"
							margin="0px 0px 0px 20px"
							md-margin="20px 0px 0px 0px"
							href="/kontakti"
							text-decoration-line="initial"
						>
							Контакти
						</Link>
					</Box>
				</Components.QuarklycommunityKitMobileSidePanel>
			</Box>
		</Section>
		<Section padding="6rem 0 24px 0">
			<Text font="--headline2">
				{"  "}Політика конфіденційності
			</Text>
			<Text font="--base">
				Ми в EduQuest з повагою ставимося до вашої конфіденційності. Ця політика пояснює, як ми збираємо, використовуємо, зберігаємо та захищаємо вашу особисту інформацію під час використання нашого вебсайту та сервісів.
			</Text>
			<Text font="--headline4">
				{"  "}Збір інформації
			</Text>
			<Text font="--base">
				Ми можемо збирати наступні типи даних:
				<br />
				<br />
				{"  "}- Ваше ім'я, контактну інформацію та електронну адресу.
				<br />
				{"  "}- Інформацію про ваші бронювання, оренди велосипедів та пов'язані послуги.
				<br />
				{"  "}- Дані про ваші уподобання для покращення якості наших сервісів.
			</Text>
			<Text font="--headline4">
				{"  "}Використання інформації
			</Text>
			<Text font="--base">
				Ми використовуємо ваші дані для:
				<br />
				<br />
				{"  "}- Обробки ваших запитів та замовлень.
				<br />
				{"  "}- Забезпечення якісного обслуговування клієнтів.
				<br />
				{"  "}- Надсилання інформаційних листів, акцій та оновлень.
			</Text>
			<Text font="--headline4">
				{"  "}Захист даних
			</Text>
			<Text font="--base">
				{"  "}Ми використовуємо сучасні технології шифрування та безпеки для захисту вашої інформації від несанкціонованого доступу, втрати чи розголошення.
			</Text>
			<Text font="--headline4">
				{"  "}Передача третім сторонам
			</Text>
			<Text font="--base">
				{"  "}Ми не передаємо вашу особисту інформацію третім сторонам, за винятком випадків, передбачених законом, або для забезпечення виконання наших послуг через перевірених партнерів.
			</Text>
			<Text font="--headline4">
				{"  "}Ваші права
			</Text>
			<Text font="--base">
				Ви маєте право:
				<br />
				<br />
				{"  "}- Отримувати доступ до ваших даних.
				<br />
				{"  "}- Вносити зміни або видаляти вашу особисту інформацію.
				<br />
				{"  "}- Відмовлятися від отримання рекламних листів.
			</Text>
			<Text font="--headline4">
				{"  "}Зміни в політиці
			</Text>
			<Text font="--base">
				{"  "}Ми залишаємо за собою право оновлювати цю політику в будь-який час. Всі зміни будуть публікуватися на цій сторінці.
			</Text>
			<Text font="--headline4">
				{"  "}Контакти
			</Text>
			<Text font="--base">
				Якщо у вас виникли питання щодо нашої політики конфіденційності, будь ласка, зв'яжіться з нами за адресою info@eduquest24.live
			</Text>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"60da14fa3f4eb1001ea27687"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});